import { DateTime } from "luxon"

const today = new Date()

const formatAMPM = (date, format = 'string') => {
  if (!(date instanceof Date) || isNaN(date)) return ''
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let amPm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours ? hours : 12
  minutes = minutes < 10 ? '0' + minutes : minutes
  if (format === 'array') return [hours, minutes, amPm]
  return hours + ':' + minutes + ' ' + amPm
}
const removeTimezoneFromISO = time => time.slice(0, -6)
const removeDateFromDateTimeISO = time => time.split('T')[1]
const clearTimeZone = time => removeDateFromDateTimeISO(removeTimezoneFromISO(time))

const formatDateObj = date => DateTime.fromISO(date)  // DateTime obj
const formatDateToJS = date => DateTime.fromISO(date).toJSDate()  // Js Date 
const formatDateMed = date => DateTime.fromISO(date).toLocaleString(DateTime.DATE_HUGE) // Friday, October 14, 1983
const formatDateMedMed = date => DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED) //  Oct 14, 1983
const formatDateISOShort = date => DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT) //13/05/2022
const formatDateToFull = date => DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_FULL) // Friday, October 14, 1983

const formatDate = date => DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_SHORT) // 4/5/2023
const formatDateToMed = date => DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_MED) // Oct 14, 1983
const formatDateToISOShort = date => DateTime.fromJSDate(date).toISODate() // '1982-05-25'
const formatDateToISO = date => removeTimezoneFromISO(DateTime.fromJSDate(date).toISO()) // '2017-04-22T20:47:05.335'

const formatTime = time => formatAMPM(DateTime.fromISO(time).toJSDate()) // 11:55 am
const formatTimeFromJS = time => formatAMPM(time) // 11:55 am
const formatTimeFromJSArray = time => formatAMPM(time, 'array') // [11, 55, "am"]
const formatDateToTimeISO = time => clearTimeZone(DateTime.fromJSDate(time).toISO()) // '20:47:05.335'
const formatDateToTimeHour = time => DateTime.fromJSDate(time).hour // '20'

const formatMultiDateToMed = (dateList = [], t) => {
  let months = []
  let item = {}

  if (dateList.length === 0) return ''

  dateList.forEach(d => {
    const month = d.getMonth()
    const index = months.findIndex(m => m.month === month)
    const date = DateTime.fromJSDate(d)
    if (index === -1) {
      item = {
        month: month,
        monthAndYear: `${t('of')} ${date.monthShort} ${date.year}`,
        days: [date.day]
      }
      months.push(item)
    } else months[index].days.push(date.day)
  })

  return months.map(item => `${item.days.join(", ")} ${item.monthAndYear}`).join(", ")
} // 14,15,16 of Oct 1983, 5 of Nov 1983  

const getAllTime = () => {
  const start = clearTimeZone(DateTime.local().set({ hour: 0, minute: 0, second: 0 }).toISO())
  const end = clearTimeZone(DateTime.local().set({ hour: 23, minute: 59, second: 59 }).toISO())
  return { start, end }
}

const isToday = date => DateTime.fromISO(date).hasSame(DateTime.local(), "day") // true or false

const isTodayFromJS = date => DateTime.fromJSDate(new Date(date)).hasSame(DateTime.local(), 'day');  // true or false

const isAllDay = ({ entry_time, exit_time }) => {

  const _entry_time = formatDateToJS(entry_time)
  const _exit_time = formatDateToJS(exit_time)

  let entry_hour = _entry_time.getHours()
  let entry_minute = _entry_time.getMinutes()
  let exit_hour = _exit_time.getHours()
  let exit_minute = _exit_time.getMinutes()

  return (
    entry_hour === 0 &&
    entry_minute === 0 &&
    exit_hour === 23 &&
    exit_minute === 59
  )
}

const areDatesEqualFromJS = (date1, date2) => {
  return DateTime.fromJSDate(date1).hasSame(DateTime.fromJSDate(date2), 'day')
}


export {
  today,
  isToday,
  isTodayFromJS,
  isAllDay,
  getAllTime,
  formatDateObj,
  formatDateMed,
  formatDateMedMed,
  formatDateToJS,
  formatDateISOShort,
  formatMultiDateToMed,
  formatDate,
  formatDateToMed,
  formatDateToISOShort,
  formatDateToISO,
  formatTime,
  formatDateToFull,
  formatTimeFromJS,
  formatDateToTimeHour,
  formatTimeFromJSArray,
  formatDateToTimeISO,
  removeTimezoneFromISO,
  removeDateFromDateTimeISO,
  areDatesEqualFromJS
}