import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react'
import { connect } from 'react-redux'

import {
  useTheme,
  Stack
} from '@mui/material'


import { DataGrid } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next'

import Layout from '../../common/components/Layout/Layout'
import VisitForm from '../../common/components/VisitForm/VisitForm';
import { api } from '../../api/api'
import './Visits.sass'

import {
  setLoading,
  defaultSuccess,
  defaultCatch
} from '../../state/actions'

import {
  debounce,
  getCompany,
  getNoRowOverlay
} from '../../common/utilities/utilities'
//import SynchronizationStatus from '../../common/components/SynchronizationStatus/SynchronizationStatus'
// import { SYNCHRONIZED } from '../../variables'
import VisitorsFilter from '../../common/components/VisitorsFilter/VisitorsFilter'
import TableMenuGenerator from '../../common/components/TableMenuGenerator/TableMenuGenerator'
import TableButtonGenerator from '../../common/components/TableButtonGenerator/TableButtonGenerator'
import { formatTime } from '../../common/utilities/FormatDate'
import EditDialog from '../../common/components/EditDialog/EditDialog';
import DetailDialog from '../../common/components/DetailDialog/DetailDialog'

function Visitors(props) {

  const {
    building_id,
    setLoading,
    defaultSuccess,
    defaultCatch,
    companies = []
  } = props

  const refBuilding = useRef()

  const { t } = useTranslation()
  const theme = useTheme()

  const [visits, setVisits] = useState([])
  const [prefix, setPrefix] = useState('')
  const [selectedItem, setSelectedItem] = useState({})
  const [detail, setDetail] = useState(false)
  const [edit, setEdit] = useState(false)


  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(15)
  const [rowCount, setRowCount] = useState(0)


  const clearVisits = visits => {
    return visits.map(visit => {
      return {
        ...visit,
        more: visit.id
      }
    })
  }

  const onShowDetail = useCallback(_item => {
    setSelectedItem(_item)
    setDetail(true)
  }, [])

  const onShowEdit = useCallback(_item => {
    setSelectedItem(_item)
    setEdit(true)
  }, [])

  const fetchVisits = useCallback((filters = {}) => {
    const params = {
      prefix: filters.prefix,
      page: filters.page + 1,
      page_size: filters.pageSize
    }

    setLoading(true)
    api.get('/visits', { params })
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        setVisits(clearVisits(data.visits))
        setRowCount(data.total)
      })
      .catch(defaultCatch)
  }, [defaultCatch, defaultSuccess, setLoading])

  const loadVisits = () => {
    const prefix = ''
    const page = 0
    const pageSize = 15
    setPrefix(prefix)
    setPage(page)
    setPageSize(pageSize)
    fetchVisits({ prefix, page, pageSize })
  }

  useEffect(() => {
    if (!refBuilding.current) {
      refBuilding.current = 1
      return
    }

    loadVisits()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [building_id])

  useEffect(() => {
    fetchVisits({ prefix, page, pageSize })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const updateExiTime = useCallback(() => {
    fetchVisits({ prefix, page, pageSize })
  }, [fetchVisits, page, pageSize, prefix])


  const columns = useMemo(() => [
    { sortable: false, headerName: t('Identification'), field: 'identification', width: '200' },
    { sortable: false, headerName: t('name'), field: 'first_name', width: '200' },
    { sortable: false, headerName: t('Comes from'), field: 'comes_from', width: '150' },
    {
      sortable: false,
      headerName: t('Go to'),
      field: 'company_id',
      width: '150',
      valueFormatter: ({ value }) => getCompany(value, companies)
    },
    {
      sortable: false,
      headerName: t('Entry time'),
      field: 'entry_time',
      width: '150',
      valueFormatter: ({ value }) => formatTime(value)
    },
    {
      sortable: false,
      headerName: t('Exit time'),
      field: 'exit_time',
      width: '150',
      headerClassName: 'transparent',
      renderCell: props =>
        <TableButtonGenerator
          updateExiTime={updateExiTime}
          {...props}
        />
    },
    { sortable: false, headerName: t('Card'), field: 'card_number', width: '150' },
    {
      sortable: false,
      headerName: '',
      field: 'actions',
      headerClassName: 'transparent',
      renderCell: props =>
        <TableMenuGenerator
          onShowDetail={onShowDetail}
          onShowEdit={onShowEdit}
          {...props}
        />
    },
  ], [t, companies, updateExiTime, onShowDetail, onShowEdit])

  const onFilterVisits = prefix => {
    const page = 0
    const pageSize = 15
    setPage(page)
    setPageSize(pageSize)
    fetchVisits({ prefix, page, pageSize })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onEfficientSearch = useCallback(
    debounce(prefix => onFilterVisits(prefix), 1000),
    []
  )

  const onChangePrefix = prefix => {
    setPrefix(prefix)
    onEfficientSearch(prefix)
  }

  const onChangePageSize = pageSize => {
    const page = 0
    setPage(page)
    setPageSize(pageSize)
    fetchVisits({ prefix, page, pageSize })
  }

  const onChangePage = page => {
    setPage(page)
    fetchVisits({ prefix, page, pageSize })
  }

  const labelDisplayedRows = useCallback(
    ({ from, to, count }) => `${from}–${to} ${t('of')} ${count !== -1 ? count : `${t('more than')} ${to}`}`
    , [t])

  const onCloseEditDialog = () => {
    setEdit(false)
    setSelectedItem({})
  }

  return (
    <Layout className="Visitors">
      <VisitForm
        loadVisits={loadVisits}
      />
      <div className='visit-list'>
        {/* <SynchronizationStatus status={SYNCHRONIZED} /> */}
        <Stack
          className='main-wrapper'
          direction="column"
          spacing={2}
        >
          <VisitorsFilter
            prefix={prefix}
            setPrefix={onChangePrefix}
          />
          <div className='table-wrapper'>
            <DataGrid
              rows={visits}
              columns={columns}
              disableSelectionOnClick
              disableColumnMenu
              components={{ NoRowsOverlay: () => getNoRowOverlay(theme, t) }}
              componentsProps={{
                pagination: {
                  labelRowsPerPage: t('rows per page:'),
                  labelDisplayedRows: labelDisplayedRows
                }
              }}
              pageSize={pageSize}
              page={page}
              onPageChange={onChangePage}
              onPageSizeChange={onChangePageSize}
              rowsPerPageOptions={[15, 50, 100]}
              pagination
              paginationMode="server"
              rowCount={rowCount}
            />
          </div>
        </Stack>
      </div>
      <DetailDialog
        open={detail}
        onClose={() => setDetail(false)}
        item={selectedItem}
      />
      <EditDialog
        open={edit}
        onClose={onCloseEditDialog}
        fetchVisits={fetchVisits}
        item={selectedItem}
      />
    </Layout>
  )
}


const mapStateToProps = state => {
  return {
    companies: state.profile.companies,
    building_id: state.profile.building_id
  }
}

const mapDispatchToProps = {
  setLoading,
  defaultSuccess,
  defaultCatch
}

export default connect(mapStateToProps, mapDispatchToProps)(Visitors)