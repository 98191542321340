import { Button, Dialog, DialogContent, Divider, IconButton, Stack, useMediaQuery, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useCallback, useState } from "react"
import {
  Close as CloseIcon,
  ArrowBack as ArrowBackIcon
} from '@mui/icons-material'

import { defaultFunction, getCompany } from "../../utilities/utilities"
import { formatTime } from "../../utilities/FormatDate"
import PrintTicketModal from "../PrintTicketModal/PrintTicketModal"
import { connect } from "react-redux"

function DetailDialog(props) {
  const {
    open = false,
    onClose = defaultFunction,
    companies = [],
    item = {},
  } = props

  const { t } = useTranslation()
  const theme = useTheme()
  const isLarge = useMediaQuery(theme.breakpoints.up('md'))

  const [openPrintTicket, setOpenPrinTicket] = useState(false)

  const {
    identification = '',
    company_id = '',
    comes_from = '',
    entry_time = '',
    exit_time = '',
    card_number = '',
    notes = '',
    has_laptop = false,
    laptop_serie = ''
  } = item

  const getSelectedItemFullName = useCallback(() => {
    const {
      first_name = '',
      last_name_one = '',
      last_name_two = ''
    } = item

    return `${first_name} ${last_name_one} ${last_name_two}`
  }, [item])

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open={open}
      fullScreen={!isLarge}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title">
      <DialogContent className="dialog-info-content">
        <Stack gap={2} alignItems="center">
          <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
            <Stack direction="row" alignItems="center" gap={1}>
              <IconButton
                aria-label="close"
                onClick={onClose}
              >
                <ArrowBackIcon color='primary' />
              </IconButton>
              <label>
                {
                  card_number ?
                    t('Card x in use', { card_number }) :
                    t('Visit')
                }
              </label>
            </Stack>
            <IconButton
              aria-label="close"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider sx={{ width: '100%' }} />
          <Stack className="detail-info" gap={2}>
            <label>{t('Identification')}: <span>{identification}</span></label>
            <label>{t('name')}: <span>{getSelectedItemFullName()}</span></label>
            <label>{t('go to')}: <span>{getCompany(company_id, companies)}</span></label>
            <label>{t('comes from')}: <span>{comes_from}</span></label>
            <label>{t('Entry time')}: <span>{formatTime(entry_time)}</span></label>
            <label>{t('Exit time')}: <span>{exit_time ? formatTime(exit_time) : t('unregistered')}</span></label>
            {card_number && <label>{t('Card')}: <span>{card_number}</span></label>}
            <label>{t('Laptop')}: <span>{has_laptop && laptop_serie ? laptop_serie : t('unregistered')}</span></label>
            <label>{t('Notes')}: <span>{notes ? notes : t('nothing')}</span></label>
          </Stack>
          <Stack spacing={2} justifyContent="center">
            <Button
              size="large"
              sx={{ width: '469px' }}
              variant="outlined"
              onClick={() => setOpenPrinTicket(true)} >
              Ver sticker de visitante
            </Button>
            <Button
              size="large"
              sx={{ width: '469px' }}
              variant="contained"
              color="primary"
              onClick={onClose} >
              {t('accept')}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
      <PrintTicketModal
        open={openPrintTicket}
        setOpen={setOpenPrinTicket}
        item={item}
      />
    </Dialog>
  )
}

const mapStateToProps = state => {
  return {
    companies: state.profile.companies
  }
}

export default connect(mapStateToProps)(DetailDialog)