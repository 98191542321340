import React from 'react';
import { Routes, Route, BrowserRouter, useLocation, Navigate, useNavigate } from "react-router-dom"
import { connect } from "react-redux"
import Visitors from './pages/VisitsPage/Visits'
import PreRegisters from './pages/PreRegistersPage/PreRegisters'
import NotFound from './pages/NotFoundPage/NotFound'
import Login from './pages/LoginPage/Login'
import Broken from './pages/BrokenPage/Broken'

import Logout from './pages/LogoutPage/Logout'
import LoadPage from './pages/LoadPage/LoadPage'
import { logout } from './state/actions/index'

function Auth(props) {
  const { children, profile, logout } = props
  const navigate = useNavigate()
  let location = useLocation()
  if (!profile.email) {
    logout(navigate)
    return <Navigate to="/login" state={{ from: location }} />
  }
  return children
}

const mapStateToProps = state => {
  return { profile: state.profile }
}

const mapDispatchToProps = {
  logout
}

const RequireAuth = connect(mapStateToProps, mapDispatchToProps)(Auth)

function RoutingSystem() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/load" element={<RequireAuth><LoadPage /></RequireAuth>} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/" element={<RequireAuth><Visitors /></RequireAuth>} />
        <Route path="/pre-registration" element={<RequireAuth><PreRegisters /></RequireAuth>} />
        <Route path="/broken" element={<Broken />} />
        <Route path='*' exact={true} element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default RoutingSystem
