import React, { useCallback, useState } from 'react'
import "./VisitForm.sass"
import {
  Button,
  Stack,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  IconButton,
  Autocomplete,
  TextField,
  Alert,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  LocalSeeOutlined as LocalSeeOutlinedIcon,
  QrCodeOutlined as QrCodeOutlinedIcon,
  Close as CloseIcon
} from '@mui/icons-material'

import { useNavigate } from 'react-router-dom'

import {
  setLoading,
  defaultSuccess,
  defaultCatch,
  setSnackbar,
} from '../../../state/actions'

import Field from '../Field/Field'
import { debounce, defaultFunction, getCompanies } from '../../utilities/utilities'
import { connect } from 'react-redux'
import { api } from '../../../api/api'
import VisitAutoComplete from '../VisitAutoComplete/VisitAutoComplete'
import { DEFAULT_AUTOCOMPLETE } from '../../../variables'
import PhotoDialog from '../PhotoDialog/PhotoDialog'
import QRDialog from '../QRDialog/QRDialog'
import IDDialog from '../IDDialog/IDDialog'

function VisitForm(props) {
  const {
    enabled_visit_photo,
    enabled_visit_qr,
    enabled_identification_photo,
    setLoading,
    defaultSuccess,
    defaultCatch,
    setSnackbar,
    companies = [],
    loadVisits = defaultFunction
  } = props

  const { t } = useTranslation()
  const navigate = useNavigate()

  const [open, setOpen] = useState(false)
  const [isInvited, setIsInvited] = useState(false)
  const [identification, setIdentification] = useState('')
  const [first_name, setFirstName] = useState('')
  const [last_name_one, setLastNameOne] = useState('')
  const [last_name_two, setLastNameTwo] = useState('')
  const [comes_from, setComesFrom] = useState('')
  const [company, setCompany] = useState(DEFAULT_AUTOCOMPLETE)
  const [hasCardNumber, setHasCardNumber] = useState(false)
  const [has_laptop, setHasLaptop] = useState(false)
  const [card_number, setCardNumber] = useState('')
  const [laptop_serie, setLaptopSerie] = useState('')
  const [notes, setNotes] = useState('')
  const [openQRDialog, setOpenQRDialog] = useState(false)
  const [openPhotoDialog, setOpenPhotoDialog] = useState(false)
  const [photo, setPhoto] = useState('')
  const [openIDDialog, setOpenIDDialog] = useState(false)
  const [identificationPhoto, setIdentificationPhoto] = useState('')


  const onChangeCardNumber = value => {
    setHasCardNumber(value === 'true')
    setCardNumber('')
  }

  const onChangePortail = value => {
    setHasLaptop(value === 'true')
    setLaptopSerie('')
  }

  const onClosePhotoDialog = () => setOpenPhotoDialog(false)
  const onCloseQRDialog = () => setOpenQRDialog(false)

  const setVisit = useCallback(visit => {
    const {
      first_name = '',
      last_name_one = '',
      last_name_two = '',
      company_id = -1,
      comes_from = '',
      laptop_serie = '',
      has_laptop = false,
      card_number = '',
      notes = ''
    } = visit

    setFirstName(first_name)
    setLastNameOne(last_name_one)
    setLastNameTwo(last_name_two)
    setComesFrom(comes_from)
    setNotes(notes)

    if (company_id && company_id !== -1 && companies.length) {
      setCompany(companies.find(c => c.id === company_id))
    }

    if (has_laptop && laptop_serie) {
      setHasLaptop(true)
      setLaptopSerie(laptop_serie)
    } else {
      setHasLaptop(false)
      setLaptopSerie('')
    }

    if (card_number) {
      setHasCardNumber(true)
      setCardNumber(card_number)
    } else {
      setHasCardNumber(false)
      setCardNumber('')
    }
  }, [companies])

  const cleanForm = useCallback((options = {}) => {
    const {
      cleanID = true,
      cleanOpen = true
    } = options

    setIsInvited(false)
    if (cleanOpen) setOpen(false)
    if (cleanID) setIdentification('')
    setFirstName('')
    setLastNameOne('')
    setLastNameTwo('')
    setComesFrom('')
    setCompany(DEFAULT_AUTOCOMPLETE)
    setHasLaptop(false)
    setLaptopSerie('')
    setHasCardNumber(false)
    setCardNumber('')
    setPhoto('')
    setIdentificationPhoto('')
    setNotes('')
  }, [setIdentificationPhoto])

  const onSearchByInvitations = useCallback(async (value) => {
    setLoading(true)
    try {
      const response = await api.get(`invitations/${value}`)
      defaultSuccess(response)
      const { data } = response
      if (data?.invitation?.id) {
        setVisit(data.invitation)
        setIsInvited(true)
        return true
      }
    } catch (error) {
      defaultCatch(error, navigate)
    }
  }, [defaultCatch, defaultSuccess, navigate, setLoading, setVisit])


  const onSearchByVisits = useCallback(async (value) => {
    setLoading(true)
    try {
      const response = await api.get(`visits/search?identification=${value}`)
      defaultSuccess(response)
      const { data } = response
      if (data?.visit?.id) {
        setVisit(data.visit)
        setIsInvited(false)
        return true
      }
    } catch (error) {
      defaultCatch(error, navigate)
    }
  }, [defaultCatch, defaultSuccess, navigate, setLoading, setVisit])

  const onSearch = useCallback(async (value) => {
    const options = { cleanID: false, cleanOpen: false }
    let mathInvitation = false
    let mathVisit = false

    if (!value) return cleanForm(options)
    mathInvitation = await onSearchByInvitations(value)
    if (!mathInvitation) mathVisit = await onSearchByVisits(value)
    if (!(mathInvitation || mathVisit)) cleanForm(options)

  }, [onSearchByInvitations, onSearchByVisits, cleanForm])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onEfficientSearch = useCallback(
    debounce((nextValue) => onSearch(nextValue), 1000),
    []
  )

  const onChangeIdentification = value => {
    setIdentification(value)
    onEfficientSearch(value)
  }

  const onSaveImage = useCallback(async (id) => {
    if (!photo) return;

    try {
      setLoading(true)
      const res = await fetch(photo)
      const file = await res.blob()
      const formData = new FormData()
      const headers = { 'Content-Type': 'multipart/form-data' }
      formData.append('visit_photo[data]', file)

      const response = await api.post(`visit/photo/${id}`, formData, { headers })
      defaultSuccess(response)
    } catch (error) {
      defaultCatch(error, navigate)
    }
  }, [defaultCatch, defaultSuccess, navigate, photo, setLoading])

  const onSaveIdentificationImage = useCallback(async (id) => {
    if (!identificationPhoto) return

    try {
      setLoading(true)
      const res = await fetch(identificationPhoto)
      const file = await res.blob()
      const formData = new FormData()
      const headers = { 'Content-Type': 'multipart/form-data' }
      formData.append('visit[data]', file)

      const response = await api.put(`visit/${id}`, formData, { headers })
      defaultSuccess(response)
    } catch (error) {
      defaultCatch(error, navigate)
    }
  }, [defaultCatch, defaultSuccess, identificationPhoto, navigate, setLoading])

  const onSaveVisit = useCallback(async () => {
    const payload = {
      visit: {
        identification,
        first_name,
        last_name_one,
        last_name_two,
        company_id: company.id,
        comes_from,
        card_number,
        notes,
        has_laptop
      }
    }

    try {
      setLoading(true)
      const response = await api.post('/visit', payload)
      defaultSuccess(response)

      const { data } = response
      if (!data.success) {
        setSnackbar({
          open: true,
          message: data.errors[0],
          severity: 'error',
          autoHideDuration: 2000,
          vertical: 'top',
          horizontal: 'right',
        })
      }
      else return data.visit
    } catch (error) {
      defaultCatch(error, navigate)
    }
  }, [card_number, comes_from, company, defaultCatch, defaultSuccess, first_name, has_laptop, identification, last_name_one, last_name_two, navigate, notes, setLoading, setSnackbar])

  const onSave = useCallback(async () => {
    setLoading(true)
    const visit = await onSaveVisit()
    if (visit) {
      if (enabled_visit_photo) await onSaveImage(visit.id)
      if (enabled_identification_photo) await onSaveIdentificationImage(visit.id)
    }

    setLoading(false)
    setSnackbar({
      open: true,
      message: t('Successfully registered visitor'),
      severity: 'success',
      autoHideDuration: 2000,
      vertical: 'top',
      horizontal: 'right',
    })
    cleanForm()
    loadVisits()
  }, [cleanForm, enabled_identification_photo, enabled_visit_photo, loadVisits, onSaveIdentificationImage, onSaveImage, onSaveVisit, setLoading, setSnackbar, t])

  const isEnableSave = useCallback(() => {
    return (
      first_name &&
      last_name_one &&
      company.id !== -1 &&
      comes_from &&
      (!hasCardNumber || card_number) &&
      (!has_laptop || laptop_serie)
    )
  }, [card_number, comes_from, company, first_name, hasCardNumber, has_laptop, laptop_serie, last_name_one])

  const onChangeCompany = (_, company) => {
    if (!company) setCompany(DEFAULT_AUTOCOMPLETE)
    else setCompany(company)
  }

  return (
    <div className={`VisitForm ${open ? 'open' : ''}`}>
      <div className='form-wrapper' onClick={() => setOpen(false)}></div>
      <div className='form' onClick={() => setOpen(true)}>
        {isInvited &&
          <Alert icon={<CheckCircleOutlineIcon fontSize="inherit" />} severity="success">
            Visita pre registrada como invitado.
          </Alert>
        }
        <Stack gap={3} className='form-inner'>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <h1>Registro de visitante</h1>
            {enabled_identification_photo &&
              <Button variant="outlined" onClick={() => setOpenIDDialog(true)}>
                <Stack direction="row" gap={1}>
                  <LocalSeeOutlinedIcon />
                  <span className={`take-credential-text ${open ? 'open' : ''}`}>Capturar credencial</span>
                </Stack>
              </Button>
            }
          </Stack>
          <Field
            label={t('Identification')}
            value={identification}
            onChange={e => onChangeIdentification(e.target.value)}
          >
            {enabled_visit_qr &&
              <Button
                sx={{
                  padding: '7px',
                  minWidth: 'auto',
                  display: open ? '' : 'none'
                }}
                variant="outlined"
                color="primary"
                size='large'
                aria-label="qr"
                onClick={() => setOpenQRDialog(true)}
              >
                <QrCodeOutlinedIcon />
              </Button>
            }
          </Field>
          <Field
            label={t('name')}
            value={first_name}
            onChange={e => setFirstName(e.target.value)}
          />
          <Field
            label={`${t('last name')} 1`}
            value={last_name_one}
            onChange={e => setLastNameOne(e.target.value)}
          />
          <Field
            label={`${t('last name')} 2`}
            value={last_name_two}
            onChange={e => setLastNameTwo(e.target.value)}
          />
          <VisitAutoComplete
            label={t('comes from')}
            value={comes_from}
            setValue={setComesFrom}
          />
          <Stack
            className='Field'
            gap={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <label>{t('go to')}</label>
            <Autocomplete
              size='small'
              fullWidth
              options={getCompanies(companies)}
              getOptionLabel={option => option.name}
              onChange={onChangeCompany}
              value={company}
              renderInput={(params) =>
                <TextField
                  {...params}
                  label={t('write')}
                  variant="filled"
                />}
            />
          </Stack>
          <Stack
            className='Field'
            gap={2}
            direction="row"
            alignItems="center"
          >
            <label>{t('Card')}</label>
            <Stack
              gap={2}
              direction="column"
            >
              <FormControl>
                <RadioGroup
                  row
                  name="card"
                  value={hasCardNumber}
                  onChange={(_, value) => onChangeCardNumber(value)}
                >
                  <FormControlLabel value={true} control={<Radio />} label={t('Yes')} />
                  <FormControlLabel value={false} control={<Radio />} label={t('No')} />
                </RadioGroup>
              </FormControl>
              {hasCardNumber &&
                <Field
                  fullWidth
                  label={t('Card number')}
                  value={card_number}
                  onChange={e => setCardNumber(e.target.value)}
                />
              }
            </Stack>
          </Stack>
          <Stack
            className='Field'
            gap={2}
            direction="row"
            alignItems="center"
          >
            <label>Portátil</label>
            <Stack
              gap={2}
              direction="column"
            >
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="laptop-radio"
                  name="laptop"
                  value={has_laptop}
                  onChange={(_, value) => onChangePortail(value)}
                >
                  <FormControlLabel value={true} control={<Radio />} label={t('Yes')} />
                  <FormControlLabel value={false} control={<Radio />} label={t('No')} />
                </RadioGroup>
              </FormControl>
              {has_laptop &&
                <Field
                  fullWidth
                  label={t('No. Serie')}
                  value={laptop_serie}
                  onChange={e => setLaptopSerie(e.target.value)}
                />
              }
            </Stack>
          </Stack>
          {enabled_visit_photo &&
            <Stack
              className='Field'
              gap={2}
              direction="row"
              alignItems="center"
            >
              <label>{t('Fotografía (Opcional)')}</label>
              {photo ?
                <div className='photo-prev-wrapper'>
                  <IconButton
                    className='close-button'
                    aria-label="close"
                    onClick={() => setPhoto('')}
                  >
                    <CloseIcon />
                  </IconButton>
                  <img src={photo} alt="user" />
                </div>
                :
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => setOpenPhotoDialog(true)}
                >
                  <Stack direction="row" gap={1}>
                    <LocalSeeOutlinedIcon />
                    {t('Take photo')}
                  </Stack>
                </Button>
              }
            </Stack>
          }
          <Field
            direction='column'
            minRows={4}
            alignItems='left'
            multiline
            label={t('Notas (Opcional)')}
            value={notes}
            placeholder="Si lo deseas puedes escribir una nota."
            onChange={e => setNotes(e.target.value)}
          />
          <Button
            disabled={!isEnableSave()}
            variant="contained"
            fullWidth
            size="large"
            onClick={onSave}
          >
            {t('confirm')}
          </Button>
        </Stack>
      </div>
      <PhotoDialog
        open={openPhotoDialog}
        setOpen={setOpenPhotoDialog}
        onClose={onClosePhotoDialog}
        setPhoto={setPhoto}
        photo={photo}
      />
      <QRDialog
        open={openQRDialog}
        onClose={onCloseQRDialog}
        onChangeIdentification={onChangeIdentification}
      />
      <IDDialog
        open={openIDDialog}
        onClose={() => setOpenIDDialog(false)}
        setPhoto={setIdentificationPhoto}
        photo={identificationPhoto}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    companies: state.profile.companies,
    enabled_visit_photo: state.profile.enabled_visit_photo,
    enabled_visit_qr: state.profile.enabled_visit_qr,
    enabled_identification_photo: state.profile.enabled_identification_photo,
  }
}

const mapDispatchToProps = {
  setSnackbar,
  setLoading,
  defaultSuccess,
  defaultCatch
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitForm)
