import React, { useEffect, useRef, useState } from 'react'

import { useReactToPrint } from 'react-to-print'
import { useTranslation } from 'react-i18next'
import { Button, Dialog, DialogContent, IconButton, Stack } from '@mui/material'

import {
  ArrowBack as ArrowBackIcon,
  AccessTime as AccessTimeIcon,
  BadgeOutlined as BadgeOutlinedIcon,
  Room as RoomIcon
} from '@mui/icons-material'

import { defaultFunction, getCompany, getFullName } from '../../utilities/utilities'
import { formatDateToMed, formatTime } from '../../utilities/FormatDate'
import './PrintTicketModal.sass'
import { api } from '../../../api/api'
import { defaultCatch, defaultSuccess } from '../../../state/actions'
import { connect } from 'react-redux'

function PrintTicketModal(props) {
  const { t } = useTranslation()
  const printRef = useRef()
  const [imageSrc, setImageSrc] = useState('')

  const {
    defaultCatch,
    defaultSuccess,
    open = false,
    companies = [],
    setOpen = defaultFunction,
    item = {},
    enabled_visit_photo
  } = props


  const {
    identification = '',
    company_id = '',
    comes_from = '',
    entry_time = '',
    photo = { id: -1 }
  } = item


  const onPrintticket = useReactToPrint({
    content: () => printRef.current,
  })

  useEffect(() => {
    const { id = -1 } = photo

    if (id === -1) return setImageSrc('')
    api.get(`visit/photo/${id}`, { responseType: "blob" })
      .then(defaultSuccess)
      .then(({ data }) => {
        if (data.size) setImageSrc(URL.createObjectURL(data))
      })
      .catch(defaultCatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [photo])


  const onClose = () => {
    setOpen(false)
  }

  return (
    <Dialog
      className='PrintTicketModal'
      fullWidth={true}
      maxWidth="sm"
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title">
      <DialogContent className="dialog-info-content">
        <Stack gap={2}>
          <Stack gap={2} direction="row" alignItems="center">
            <IconButton
              aria-label="close"
              onClick={onClose}
            >
              <ArrowBackIcon color='primary' />
            </IconButton>
            Sticker de visitante
          </Stack>

          <Stack
            direction="row"
            ref={printRef}
            style={{
              margin: 'auto',
              width: '506px',
              height: '348px',
              boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.25)',
              position: 'relative',
              overflow: 'hidden',
              border: 'solid 1px rgba(0, 0, 0, 0.15)',
            }}
            gap={3}
            alignItems="center"
            justifyContent="center"
          >
            <img
              style={{
                position: 'absolute',
                top: 0,
                left: 0
              }}
              src='border-print.png'
              alt=''
            />
            <img
              style={{
                position: 'absolute',
                bottom: 0,
                right: 0,
                transform: 'rotate(180deg)'
              }}
              src='border-print.png'
              alt=''
            />
            {
              enabled_visit_photo &&
              imageSrc &&
              <img
                src={imageSrc}
                alt='visit person'
                style={{
                  objectFit: 'cover',
                  border: 'solid 2px black',
                  width: '150px',
                  height: '150px',
                }}
              />
            }
            <Stack
              alignItems="center"
              gap={2}
              style={{
                width: '50%',
                color: 'black',
                fontSize: '14px'
              }}>
              <Stack
                gap={1}
                style={{
                  marginBotton: '20px'
                }}
              >
                <h2
                  style={{
                    fontSize: '30px',
                    color: 'black',
                    textDecoration: 'underline'
                  }}
                >
                  {getFullName(item)}
                </h2>
                <Stack alignItems="center">
                  <label>{t('entrance')}: {formatDateToMed(new Date())}</label>
                </Stack>
              </Stack>
              <Stack sx={{ color: 'black' }} gap={1}>
                <Stack alignItems="center" direction="row" gap={1} ><BadgeOutlinedIcon sx={{ width: '20px' }} /> <label>{t('Identification')}: <span>{identification}</span></label></Stack>
                <Stack alignItems="center" direction="row" gap={1} ><AccessTimeIcon sx={{ width: '20px' }} /> <label>{t('Entry time')}: <span>{formatTime(entry_time)}</span></label></Stack>
                <Stack alignItems="center" direction="row" gap={1} ><RoomIcon sx={{ width: '20px' }} /> <label>{t('go to')}: <span>{getCompany(company_id, companies)}</span></label></Stack>
                <Stack alignItems="center" direction="row" gap={1} ><RoomIcon sx={{ width: '20px' }} /> <label>{t('comes from')}: <span>{comes_from}</span></label></Stack>
              </Stack>
            </Stack>
          </Stack>
          <Button
            size="large"
            fullWidth
            variant="contained"
            color="primary"
            onClick={onPrintticket} >
            {t('Print')}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog >
  )
}

const mapDispatchToProps = {
  defaultSuccess,
  defaultCatch
}


const mapStateToProps = state => {
  return {
    companies: state.profile.companies,
    enabled_visit_photo: state.profile.enabled_visit_photo,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrintTicketModal)

