import React, {useState, useCallback, useEffect, useMemo} from "react";
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { api } from '../../api/api'

import {
  setLoading,
  defaultSuccess,
  defaultCatch
} from '../../state/actions'

import Layout from '../../common/components/Layout/Layout'
import SwipeableCalendar from '../../common/components/SwipeableCalendar/SwipeableCalendar'
import {
  getNoRowOverlayInvitations,
  getNoResultsOverlayInvitations,
  debounce
} from '../../common/utilities/utilities'

import { 
  useTheme,
  Stack, 
  TextField,
  InputAdornment,
  Alert
} from "@mui/material";
import { 
  Search as SearchIcon,
  Event as EventIcon
} from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';


import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon'
import { subMonths, addMonths, isAfter, isBefore } from 'date-fns';
import { areDatesEqualFromJS, formatDateToFull, formatDateToMed, isTodayFromJS, today } from "../../common/utilities/FormatDate";

import './PreRegisters.sass'

const PreRegisters = (props) => {

  const {
    setLoading,
    defaultSuccess,
    defaultCatch,
  } = props
  
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()

  const [date, setDate] = useState([today, today])
  const [openCalendar, setOpenCalendar] = useState(false)
  const [calendarDate, setCalendarDate] = useState(date)
  const [invitations, setInvitations] = useState([])
  const [rowCount, setRowCount] = useState(0)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(15)
  const [prefix, setPrefix] = useState('')
  const [searching, setSearching] = useState(false)
  const [disabledOnContinue, setDisabledOnContinue] = useState(false)
  const [calendarAlertMessage, setCalendarAlertMessage] = useState('');

  const clearInvitations = useCallback((invitations) => {
    return invitations?.map((row, index) => ({
      id: index,
      entry_time: DateTime.fromFormat(row[0].value, 'dd-LLLL-yyyy', { locale: 'es' }).toJSDate(),
      full_name: row[1].value,
      identification: row[2].value,
      comes_from: row[3].value,
      company_id: row[4].value,
      host: row[5].value,
      building: row[6].value,
    })).sort((a, b) => a.entry_time - b.entry_time);
    
  }, [])

  const fetchInvitations = useCallback((filters = {}) => {
    const params = {
      start_date: date[0],
      end_date: date[1],
      prefix: filters.prefix,
      page: filters.page + 1,
      page_size: filters.pageSize
    }

    setLoading(true)
    api.get('/invitations', { params })
      .then(defaultSuccess)
      .then(({ data }) => {
        if (!data.success) return
        setInvitations(clearInvitations(data.items))
        setRowCount(data.total)
      })
      .catch(error => defaultCatch(error, navigate, fetchInvitations))
  }, [defaultCatch, defaultSuccess, navigate, setLoading, clearInvitations, date])


  useEffect(() => {
    fetchInvitations({ prefix, page, pageSize, date })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date])

  useEffect(() => {
    const minAllowedDate = subMonths(today, 3);
    const maxAllowedDate = addMonths(today, 3);

    if (isBefore(calendarDate[0], minAllowedDate)) {
      setDisabledOnContinue(true);
      setCalendarAlertMessage(t('Calendar alert past'));
    } else if (isAfter(calendarDate[1], maxAllowedDate)) {
      setDisabledOnContinue(true);
      setCalendarAlertMessage(t('Calendar alert future'));
    } else {
      setDisabledOnContinue(false);
      setCalendarAlertMessage('');
    }
  }, [calendarDate, t]);

  const onFilterInvitations = useCallback(prefix => {
    const page = 0
    const pageSize = 15
    setPage(page)
    setPageSize(pageSize)
    fetchInvitations({ prefix, page, pageSize })
  }, [fetchInvitations, setPage, setPageSize])

  const onEfficientSearch = useMemo(() => {
    return debounce((prefix) => {
      onFilterInvitations(prefix);
    }, 1000);
  }, [onFilterInvitations]);

  const onChangePrefix = prefix => {
    setPrefix(prefix)
    prefix ? setSearching(true) : setSearching(false)
    onEfficientSearch(prefix)
  }

  const onChangePageSize = pageSize => {
    const page = 0
    setPage(page)
    setPageSize(pageSize)
    fetchInvitations({ prefix, page, pageSize })
  }

  const onChangePage = page => {
    setPage(page)
    fetchInvitations({ prefix, page, pageSize })
  }

  const getFilterDate = () => {
    const [start, end] = date
    if (areDatesEqualFromJS(start, end)) return `${isTodayFromJS(start) ? t('today') : formatDateToMed(start)}`
    return `${isTodayFromJS(start) ? t('today') : formatDateToMed(start)} - ${isTodayFromJS(end) ? t('today') : formatDateToMed(end)}`
  }
 
  const onContinue = () => {
    setDate(calendarDate)
    setOpenCalendar(false)
  }

  const onCloseCalendar = useCallback(() => {
    setOpenCalendar(false)
    setCalendarDate(date)
  }, [date])

  const renderCalendarAlert = useCallback(() => {
    if (disabledOnContinue && calendarAlertMessage) {
      return (
        <Alert severity="error" icon={false} style={{ color: "#CD2A26" }}>
          {calendarAlertMessage}
        </Alert>
      );
    }
    return null;
  }, [disabledOnContinue, calendarAlertMessage]);

  const columns = useMemo(() => [
    {
      sortable: false,
      headerName: t('Entry time'),
      field: 'entry_time',
      width: '200',
      valueFormatter: ({ value }) => formatDateToFull(value)
    },
    { sortable: false, headerName: t('name'), field: 'full_name', width: '200' },
    { sortable: false, headerName: t('Identification'), field: 'identification', width: '200' },
    { sortable: false, headerName: t('Comes from'), field: 'comes_from', width: '200' },
    {
      sortable: false,
      headerName: t('Go to'),
      field: 'company_id',
      width: '200',
    },
    {
      sortable: false,
      headerName: t('host'),
      field: 'host',
      width: '200',
    },
    {
      sortable: false,
      headerName: t('building'),
      field: 'building',
      width: '200',
    }
  ], [t])

  const labelDisplayedRows = useCallback(({ from, to, count }) => `${from}–${to} ${t('of')} ${count !== -1 ? count : `${t('more than')} ${to}`}`, [t])
  
  return (
    <Layout className='pre-registers'>
      <Stack
        direction={"column"}
        spacing={2}
        padding={'28px 60px'}
        
      >
        <h1>{t("Visitors Pre-registration")}</h1>
        <Stack
          direction={"column"}
          spacing={2}
        >
          <Stack
            direction={"row"}
            spacing={2}
            gap={2}
            alignItems={"flex-end"}
            flexWrap={"wrap"}
            width={"100%"}
          >
            <TextField
              placeholder={t("Search")}
              onChange={e => onChangePrefix(e.target.value)}
              InputProps={{
                style: {
                  height: '32px',
                  fontSize: '13px'
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Stack 
              direction={"row"}
              columnGap={1}
              alignItems={"center"}
              className='date-picker'
              flexWrap
            >
              <label>
                {`${t("Visit Date")}:`}
              </label>
              <TextField
                value={getFilterDate()}
                onClick={e => setOpenCalendar(true)}
                fullWidth
                hiddenLabel
                autoComplete="off"
                size="small"
                InputProps={{
                  style: {
                    fontSize: '13px'
                  },
                  endAdornment:
                    <InputAdornment position="end">
                      <EventIcon/>
                    </InputAdornment>
                }}
              />
              <SwipeableCalendar
                type="range"
                open={openCalendar}
                date={date}
                setDate={setCalendarDate}
                setOpen={setOpenCalendar}
                onClose={onCloseCalendar}
                onChange={date => setCalendarDate(date)}
                onContinue={onContinue}
                renderMessage={renderCalendarAlert}
                clearable
                isDisabled={disabledOnContinue}
              />
            </Stack>
          </Stack>
          <div className='table-wrapper'>
            <DataGrid
              className='pre-registers-data-grid'
              rows={invitations}
              columns={columns}
              pageSize={pageSize}
              page={page}
              rowsPerPageOptions={[15, 50, 100]}
              components={{ 
                NoRowsOverlay: () => getNoRowOverlayInvitations(theme, t),
                NoResultsOverlay: () => searching && getNoResultsOverlayInvitations(theme, t)
              }}
                componentsProps={{
                  pagination: {
                    labelRowsPerPage: t('rows per page:'),
                    labelDisplayedRows: labelDisplayedRows
                  }
                }}
              pagination
              disableSelectionOnClick
              disableColumnMenu
              paginationMode="server"
              rowCount={rowCount}
              onPageChange={onChangePage}
              onPageSizeChange={onChangePageSize}
              density="compact"
              
            />
          </div>
        </Stack>
      </Stack>
    </Layout>
  );
}

const mapDispatchToProps = {
  setLoading,
  defaultSuccess,
  defaultCatch
}

export default connect(null, mapDispatchToProps)(PreRegisters)